.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(21, 15, 15, 0.837);
  border: 1px solid black;
  padding: 20px;
  max-width: 2000px;
  max-height: 2000px;
  overflow-y: auto;
}

.modal p {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
  
.modal::-webkit-scrollbar {
  width: 8px; /* Set width of the scrollbar */
}

.modal::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5); /* Set color of the scrollbar thumb */
  border-radius: 8px; /* Round the edges of the scrollbar thumb */
}

.modal::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2); /* Set color of the scrollbar track */
  border-radius: 8px; /* Round the edges of the scrollbar track */
}
