.sidenav {
  min-width: 150px;
  max-width: 20%;
  transition: width 0.3s ease-in-out;
  background-color: rgb(10, 25, 41);
  padding-top: 1px;
}

.sidenavClosed {
  composes: sidenav;
  transition: width 0.3s ease-in-out;
  min-width: 60px;
  max-width: 10%;
  height: 100vh;
}

.sideitem {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  color: #b2bac2;
  text-decoration: none;
  overflow: hidden;
}

.linkText {
  padding-left: 16px;
}

.sideitem:hover {
  background-color: #244f7d1c;
}

.menuBtn {
  position: absolute;
  bottom: 4%;
  left: 1%;
  transform: translateX(-50%);
  color: #b2bac2;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-left: 20px;
}

.logoutBtn {
  position: absolute;
  bottom: 1%;
  left: 1%;
  transform: translateX(-50%);
  color: #b2bac2;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .sidenav {
    min-width: 100%;
    max-width: 100%;
  }

  .sidenavClosed {
    min-width: 60px;
    max-width: 10%;
  }

  .menuBtn {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
    padding-left: 0;
  }

  .logoutBtn {
    position: absolute;
    bottom: 0;
    margin-top: 80%;
  }

  .sideitem {
    padding: 10px;
  }

  .linkText {
    display: none;
  }
}

body {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  justify-content: center;
  width: 95vw;
}

table {
  border-collapse: collapse;
  border-spacing: 10;
}

th,
td {
  border: 1px solid black;
  padding: 10px;
}
